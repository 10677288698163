<template>
  <link rel="stylesheet" href="https://cdn.staticfile.org/font-awesome/4.7.0/css/font-awesome.css">
  <link rel="stylesheet" href="src/assets/normalize.css">
  <div class="tile_up">
    <div class="up">
      <!--此处对应顶部黑条-->
      <div class="informationCard">
        <div class="phone">
          <div class="logo">
            <i style="font-size:0.22rem;color:#ffffff" class="fa">&#xf095</i>
          </div>
          <div class="num">
            15865472189
          </div>
        </div>
        <div class="e-mail" v-show="false">
          <div class="logo">
            <i style="font-size:0.22rem;color: #ffffff" class="fa">&#xf0e0</i>
          </div>
          <div class="num">
            langyungongyesheji@163.com
          </div>
        </div>
        <div class="time">
          <div class="logo">
            <i style="font-size:0.22rem;color: #ffffff" class="fa">&#xf017</i>
          </div>
          <div class="num">
            9:00-17:00
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tile_down">
    <!--下半部白条-->
    <div class="down">
      <div class="left">
        <div class="logo" @click="this.$router.push('/Home')"></div>
      </div>
      <div class="right">
        <div class="list">
          <div>
            <a @click="this.$router.push('/Home')" >首页</a>
            <i v-show="nowPage !== 'Home'"></i>
            <i v-show="nowPage === 'Home'" style="display: block;width: 100%;height: .06rem;background-color: #ec414d;"></i>
          </div>
          <div>
            <a @click="this.$router.push('/Platform');">云平台</a>
            <i v-show="nowPage !== 'Platform'"></i>
            <i v-show="nowPage === 'Platform'" style="display: block;width: 100%;height: .06rem;background-color: #ec414d;"></i>
          </div>
          <div class="display">
            <a @click="this.$router.push({path:'/CaseTemp',query: {caseType: '产品设计'}})" @mouseover="showSwitch" @mouseleave="closeSwitch">案例展示</a>
            <i v-show="nowPage !== 'Case'"></i>
            <i v-show="nowPage === 'Case'" style="display: block;width: 100%;height: .06rem;background-color: #ec414d;"></i>
          </div>
          <div>
            <a @click="this.$router.push('/ProjectHome')">项目中心</a>
            <i v-show="nowPage !== 'ProjectHome'"></i>
            <i v-show="nowPage === 'ProjectHome'" style="display: block;width: 100%;height: .06rem;background-color: #ec414d;"></i>
          </div>
          <div>
            <a @click="this.$router.push('/ChainList')">供应链管理</a>
            <i v-show="nowPage !== 'ChainList'"></i>
            <i v-show="nowPage === 'ChainList'" style="display: block;width: 100%;height: .06rem;background-color: #ec414d;"></i>
          </div>
          <div>
            <a href="https://www.guanzhongid.cn">活动</a>
            <i v-show="nowPage !== 'ActivityList'"></i>
            <i v-show="nowPage === 'ActivityList'" style="display: block;width: 100%;height: .06rem;background-color: #ec414d;"></i>
          </div>
        </div>
        <div class="button">
          <!--          搜索按钮-->
          <div class="search">
            <i style="font-size:0.24rem;color: #212121" class="fa" v-show="false">&#xf002</i>
          </div>
          <el-popover
              placement="bottom-start"
              :width="200"
              trigger="hover"
              content="QRCode"
          >
            <!--            二维码按钮-->
            <template #reference>
              <div class="menu" v-show="false">
                <i style="font-size:0.24rem" class="fa" >&#xf0c9</i>
              </div>
            </template>
          </el-popover>
          <div class="login">
            <button @click="this.$router.push('/Login');" v-show="this.isLogin !== '1' " style="margin-right: 0.1rem">登录</button>
            <button @click="this.$router.push('/SignUp');" v-show="this.isLogin !== '1' ">注册</button>
            <button @click="this.$router.push('/BackHome')" v-show="this.isLogin === '1'" style="margin-right: 0.1rem">个人中心</button>
            <button @click="Logout" v-show="this.isLogin === '1'">退出</button>
          </div>
        </div>
      </div>

    </div>
  </div>
    <div class="casePageSwitch" :style="casePageSwitchStyle" @mouseover="showSwitch" @mouseleave="closeSwitch">
      <div class="left">
        <div class="projectDesign" @mouseover="focus(1)" v-show="focusIndex !== 1">产品设计</div>
        <div class="focus" @mouseover="focus(1)" v-show="focusIndex === 1" @click="this.$router.push({path:'/CaseTemp',query: {caseType: '产品设计'}})">产品设计</div>
        <div class="developmentDesign" @mouseover="focus(2)" v-show="focusIndex !== 2">研发工程</div>
        <div class="focus" @mouseover="focus(2)" v-show="focusIndex === 2"  @click="this.$router.push({path:'/CaseTemp',query: {caseType: '研发工程'}})">研发工程</div>
        <div class="disseminateDesign" @mouseover="focus(3)" v-show="focusIndex !== 3">传播设计</div>
        <div class="focus" @mouseover="focus(3)" v-show="focusIndex === 3" @click="this.$router.push({path:'/CaseTemp',query: {caseType: '传播设计'}})">传播设计</div>
        <div class="spaceDesign" @mouseover="focus(4)" v-show="focusIndex !== 4">空间设计</div>
        <div class="focus" @mouseover="focus(4)" v-show="focusIndex === 4" @click="this.$router.push({path:'/CaseTemp',query: {caseType: '空间设计'}})">空间设计</div>
      </div>
      <!--右侧内容-->
      <div class="right">
        <div class="right1" v-show="focusIndex === 1">
          <div class="card_content">
            <div class="title">产品设计</div>
            <div class="intro">
              把一种设想利用合情合理的规划，详细周密的计划方案，通过各种各样的感觉形式来传达
              出来。用途上，产品设计又有外观设计和结构设计。
            </div>
          </div>
          <div class="card_link">
            <div class="website">公司官网</div>
            <div class="connection">联系我们</div>
            <div class="experience">体验设计</div>
          </div>
        </div>
        <div class="right2" v-show="focusIndex === 2">
          <div class="card_content">
            <div class="title">研发工程</div>
            <div class="intro">
              设计不能代替工程的其余阶段，但先进的设计必须贯穿于工程的各个阶段。为工程的每个阶段进行合理的设计和优化是设计工程所表达的真正意义。
            </div>
          </div>
          <div class="card_link">
            <div class="website">公司官网</div>
            <div class="connection">联系我们</div>
            <div class="experience">体验设计</div>
          </div>
        </div>
        <div class="right3" v-show="focusIndex === 3">
          <div class="card_content">
            <div class="title">传播设计</div>
            <div class="intro">
              包括印刷、手工、电子媒体、呈现手段在内的媒体怎样与人进行交流的设计方式。关注将信息传递给目标人群而创造新的媒体形态。
            </div>
          </div>
          <div class="card_link">
            <div class="website">公司官网</div>
            <div class="connection">联系我们</div>
            <div class="experience">体验设计</div>
          </div>
        </div>
        <div class="right4" v-show="focusIndex === 4">
          <div class="card_content">
            <div class="title">空间设计</div>
            <div class="intro">
              主要针对人类活动空间的设计，从简单的软装设计到复杂的建筑设计，从生活空间设计到公共空间设计，设计针对环境排布和整合。
            </div>
          </div>
          <div class="card_link">
            <div class="website">公司官网</div>
            <div class="connection">联系我们</div>
            <div class="experience">体验设计</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Header",
  data() {
    return{
      focusIndex:1,
      casePageSwitchStyle:'',
      isLogin:"0",
      nowPage:'Home'
    }
  },
  watch: {
    $route(to, from){
      this.nowPage = to.name;
    },
  },
  mounted() {
    this.isLogin = window.sessionStorage.getItem("login");

    if (this.isLogin === '1'){
      this.initWebSocket(window.sessionStorage.getItem('UserId'));
    }
  },
  methods:{
    focus(index){
      this.focusIndex = index;
    },
    showSwitch(){
      clearTimeout(this.timer);  //清除延迟执行
      this.casePageSwitchStyle = "top: 1.45rem";
    },
    closeSwitch(){
      clearTimeout(this.timer);  //清除延迟执行

      this.timer = setTimeout(()=>{   //设置延迟执行
        this.casePageSwitchStyle = "top: -7rem";
      },200);

    },
    Logout(){
      sessionStorage.clear();
      location.reload();
    },

    // 创建与客户端的websocket连接
    initWebSocket(userId){
      // ws://8081  只是把之前的http换成了ws  (http协议换成了websocket协议)
      this.websock = new WebSocket("ws://49.233.53.29:8081/websocketForUser/" + userId);
      // 下面四个是websocket生命周期函数
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen: function () {
      console.log("WebSocket连接成功");
    },
    websocketonerror: function (e) {
      console.log("WebSocket连接发生错误",e);
    },
    websocketonmessage: function (e) {
      console.log("接收到的数据：",e.data)
      if(e.data==='haveUnReadMessage'){
        this.getUnReadMessageCountForUser()
      }
    },
    websocketclose: function (e) {
      console.log("connection closed",e);
    },
    linkWebsocket: function(e){
      this.initWebSocket(e)
    },

    // 获取该用户未读消息的数量
    getUnReadMessageCountForUser(){
      let thus = this
      this.$http({
        method: 'post',
        url: '/getUnReadMessageCountForUser',
      }).then(res =>{
        thus.unReadCount=res.data.count
      })
    },
  },
  components:{

  },
}

</script>

<style scoped>

/* //动态样式下划线 */
.underline{
    width: 100%!important;
    height: 0.06rem!important;
    background-color: #ec414d!important;
}

.tile_up{
  display: flex;
  width: 100%;
  background-color: #212121;
  justify-content: center;
}

.tile_down{
  display: flex;
  width: 100%;
  background-color: #ffffff;
  justify-content: center;
}

.up{
  width: 19.2rem;
  height: 0.45rem;
  background-color: #212121;
}

.informationCard{
  height:0.45rem;
}

.informationCard div{
  float: left;
  height: 0.22rem;
  color: #0f0f0f;
}

.informationCard .phone{
  display: table-cell;
  width: 1.5rem;
  height: 0.22rem;
  margin-left: 1.8rem;
  margin-right: .5rem;
  vertical-align: middle;
  margin-top: .1rem;
  color: #ffffff;

}
.informationCard .phone .logo{
  width: 0.22rem;
  height: 0.22rem;
  margin-right: .05rem;
  margin-top: .03rem;
}

.informationCard .phone .num{
  width: 1.16rem;
  height: 0.22rem;
  font-size: .18rem;
  color: #ffffff;

}

.informationCard .e-mail{
  width: 1.98rem;
  height: 0.22rem;
  margin-right: .5rem;
  margin-top: .12rem;
  color: #ffffff;

}
.informationCard .e-mail .logo{
  width: 0.22rem;
  height: 0.22rem;
  margin-right: .05rem;
}

.informationCard .e-mail .num{
  width: .16rem;
  height: 0.22rem;
  font-size: .18rem;
  margin-right: .5rem;
  color: #ffffff;
  margin-top: -0.02rem;
}
.informationCard .time{
  width: 10.24rem;
  height: 0.22rem;
  /*由于先隐藏了邮箱那一块，暂时把左外边距去掉*/
  /*margin-left: 1rem;*/
  margin-top: .12rem;

}
.informationCard .time .logo{
  width: 0.22rem;
  height: 0.22rem;
  margin-right: .05rem;
  margin-top: .02rem;
}

.informationCard .time .num{
  height: 0.22rem;
  font-size: .18rem;
  margin-top: -0.02rem;
  color: #ffffff;
}
.informationCard .companyLogo{
  background: url("../images/companyLogo.png");
  background-size: 100%;
  width: 1.45rem;
  height: .28rem;
  font-weight: 700;
  margin-top: .1rem;
  color: #ffffff;
  cursor: pointer;
}

.down{
  position: relative;
  width: 19.2rem;
  height: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #ffffff;
  z-index: 1;
}

.down .logo{
  float: left;
  width:2.65rem;
  height: .57rem;
  margin-left: 1.6rem;
  margin-right: .7rem;
  margin-top: 0.22rem;
  background-image: url("../images/head_logo.png");
  background-size: 2.65rem .57rem;
  cursor: pointer;
}
.list {
  float: left;
  width: 8.8rem;
  text-align: center;
  margin-right: 1rem;
}
.list div{
  float: left;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: .19rem;
  font-weight: 550;
  color: black;
  margin-right: .35rem;
  box-sizing: border-box;
}

.list a{
  display: block;
  width: 100%;
  height: .94rem;
  cursor: pointer;
}

.list div:nth-child(4){
  width: 1.1rem;

}
.list div i{
  display: block;
  width: 0;
  height: .06rem;
  background-color: #ec414d;
  transition: all 0.7s;
}

.list div:hover i{
  width: 100%;
}

.button div{
  float: left;

}
.button button{
  float:left;
  width: 1.38rem;
  height: .48rem;
  font-size: .21rem;
  font-weight: 500;
  margin-top: .32rem;
  text-align: center;
  line-height: .48rem;
  color: #fef6f6;
  background-color: #ec414d;
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all .7s;


}
.button button:hover{
  background-size: 300%;
}

.button .menu{
  float: left;
  width: 0.24rem;
  height: 0.24rem;

  margin-right: .65rem;
  margin-top: .44rem;
  cursor: pointer;
}

.button .search{
  float: left;
  width: 0.24rem;
  height: 0.24rem;
  margin-right: .4rem;

  margin-top: .44rem;
  cursor: pointer;
}


.button .login{
  position: absolute;
  right: 1.52rem;
  border-radius:.05rem;
  font-weight: 700;
}

.casePageSwitch{
  left: 1rem;
  top: -7rem;
  position: absolute;
  width: 6.9rem;
  height: 3.95rem;
  z-index: 999;
  border-radius: .05rem;
  overflow: hidden;
  margin-left: 6.5rem;
}

.casePageSwitch .left{
  float: left;
  width: 1.55rem;
  height: 3.95rem;
  padding-top: .25rem;
  background-color: #252525;
}

.casePageSwitch .left div{
  width: 1.55rem;
  height: .5rem;
  color: #ffffff;
  font-size: .18rem;
  line-height: .5rem;
  text-align: center;
  cursor: pointer;
}

.casePageSwitch .left .focus{
  color: black;
  background: url('../images/Case_Page_Switch.png') no-repeat;
  background-size:100% 100%;
}


.casePageSwitch .right{
  float: left;
  width: 5.35rem;
  height: 3.95rem;
  background-color: #ffffff;
}

.casePageSwitch .right .card_content{
  float: left;
  width: 4rem;
  height: 3.95rem;
}

.casePageSwitch .right .card_content .title{
  margin-top: .8rem;
  margin-left: .35rem;
  color: #212121;
  font-size: .21rem;
  margin-bottom: .3rem;
  font-weight: 500;
}

.casePageSwitch .right .card_content .intro{
  width: 2.9rem;
  font-size: .16rem;
  color: #9a9a9a;
  margin-left: .35rem;
}

.casePageSwitch .right .card_link{
  float: left;
  width: 1.35rem;
  height: 1.96rem;
  border-left: .01rem solid #9a9a9a;
  margin-top: .38rem;
}

.casePageSwitch .right .card_link div{
  width: 1.1rem;
  height: .16rem;
  font-size: .16rem;
  text-align: center;
  color: #9a9a9a;
  margin-bottom: .15rem;
  cursor: pointer;
}

.casePageSwitch .right .card_link div:hover{
  width: 1.1rem;
  height: 0.16rem;
  font-size: 0.16rem;
  text-align: center;
  color: rgb(235,66,77);
  margin-bottom: 0.15rem;
  cursor: pointer;
}
</style>
