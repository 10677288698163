<template>
  <div class="tile_footer">
<!--    <div class="fill_up">-->
<!--    </div>-->
    <div class="footer">
      <div class="up">
        <el-space>
          <div class="informationCard">
            <div class="logo"></div>
            <!--            <div class="email">邮箱：langyungongyesheji@163.com</div>-->
            <div class="address">地址：山东省淄博市高新区柳泉路139号齐鲁电商谷B座</div>
            <div class="mail">电话：15865472189</div>
            <div class="subtitle">&ensp;&ensp;山东朗云工业设计有限责任公司·淄博冠中工业设计研究院联合共创</div>
          </div>
          <div class="link">
            <div style="display: table-cell;">
              <div class="linkTitle">云平台</div>
              <p @click="this.$router.push('/Login')">登录/注册</p>
              <p @click="toPath('field')">服务领域</p>
              <p @click="toPath('advantage')">平台优势</p>
              <p @click="toPath('process')">服务流程</p>
              <p @click="this.$router.push('/Case')">朗云案例</p>
            </div>
            <div style="display: table-cell">
              <div class="linkTitle">案例展示</div>
              <p @click="this.$router.push({path:'/CaseTemp',query:{caseType:'产品设计'}})">产品设计</p>
              <p @click="this.$router.push({path:'/CaseTemp',query:{caseType:'研发工程'}})">研发工程</p>
              <p  @click="this.$router.push({path:'/CaseTemp',query:{caseType:'传播设计'}})">传播设计</p>
              <p  @click="this.$router.push({path:'/CaseTemp',query:{caseType:'空间设计'}})">空间设计</p>
            </div>
            <div style="display: table-cell">
              <div class="linkTitle">项目中心</div>
              <p @click="this.$router.push('/ProjectHome')">正在招募的项目</p>
              <p @click="this.$router.push('/ProjectHome')">服务中的项目</p>
              <p @click="this.$router.push('/ProjectHome')">已完成的项目</p>
              <p @click="this.$router.push('/ProjectHome')">全部项目</p>
            </div>
            <div style="display: table-cell">
              <div class="linkTitle">供应链管理</div>
              <p @click="this.$router.push('/ChainList')">结构设计</p>
              <p @click="this.$router.push('/ChainList')">软硬件开发</p>
              <p @click="this.$router.push('/ChainList')">手板制造</p>
              <p @click="this.$router.push('/ChainList')">生产制造</p>
              <p @click="this.$router.push('/ChainList')">包装制造</p>
            </div>
            <div class="QR_code">
              <img src="../images/QRCode.png">
              <p>扫描二维码关注公众号</p>
            </div>
          </div>
        </el-space>
      </div>
    </div>
<!--    <div class="fill_down">-->
<!--    </div>-->
  </div>
  <div class="tile_down">
    <div class="down">
      <span>© 2021 Langyun Corporation.All rights reserved</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods:{
    toPath(id){
      this.$router.push({path: '/Platform',query:{id:id}});
      window.location.reload();
    }
  }
}
</script>

<style scoped>
p{
  cursor: pointer;
}

.tile_footer{
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid #212121;
}

.footer{
  height: 416px;
  width: 1920px;
}

.tile_up{
  display: flex;
  width: 100%;
  background: url("../images/FooterBac.png");
  background-size: 416px 200%;
  justify-content: center;
}

.up{
  border-bottom: 1px solid #212121;
  width: 100%;
  background: url("../images/FooterBac.png") top center;
  background-size: cover;
}

.informationCard{
  width: 508px;
  height: 416px;
  background: rgb(235,66,77);
  color: #ffffff;
  padding-top: 80px;
  padding-left: 68px;
  padding-right: 20px;
  /*margin-left: 146px;*/
}

.informationCard .logo{
  background: url("../images/head_logo_w.png");
  background-size: 100%;
  width: 240px;
  height: 58px;
  margin-bottom: 36px;

}

.informationCard div{
  font-size: 16px;
}
.informationCard .email,.address{
  font-size: 16px;
  margin-bottom: 20px;
}

.informationCard .subtitle{
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 5px 5px 5px 5px ;
  margin-top: 100px;
  font-size: 13px
}
.informationCard .mail{
  font-size: 16px;
  margin-bottom: 20px;
}

.informationCard button{
  width: 135px;
  height: 35px;
  font-size: 18px;
  color: #212121;
  background-color: #ffffff;
  font-weight: 700;
  border-radius:5px;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all 1s;
}

.informationCard button:hover{
  background-size: 300%;
  color: #ffffff;
}
.linkTitle{
  font-size: 21px;
  color: rgb(236,65,77);
}

.link{
  position: relative;
  float: left;
  font-size: 16px;
  margin-left: 114px;
  display: table;
}

.link .linkTitle{
  color: rgb(235,66,77);
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 30px;
}

.link div{
  width: 245px;
  /*margin-top: 70px;*/
  font-size: 16px;
  font-weight: 400;
  /*margin-right: 128px;*/

}

.link div p{
  margin-bottom: 20px;
  cursor: pointer;
  color: #212121;
}

.link div p:hover{
  color: rgb(236,65,77);
}

.tile_down{
  display: flex;
  width: 100%;
  justify-content: center;
}

.tile_down{
  display: flex;
  width: 100%;
  justify-content: center;
}

.down{
  width: 1920px;
  height: 62px;
  color: rgb(236,65,77);
  margin-top: 12px;
  font-size: 16px;
}

.QR_code{
  position: absolute;
  width: 166px;
  height: 175px;
  margin-left: 40px;
}

.QR_code img{
  width: 139px;
  height: 139px;
  margin-left: 9px;
  margin-bottom: 9px;
}

/*盒子填充start*/
.fill_up{
  width: 90%;
  height: 416px;
  background: rgb(235,66,77);
}

.fill_down{
  width: 73%;
  height: 416px;
}
/*盒子填充end*/
</style>