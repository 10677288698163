<template>
  <div id="app-control" >
    <el-affix>
      <div style="z-index: 900">
        <Header v-if="!$route.meta.showNav" />
      </div>
    </el-affix>
    <div class="mainWindow">
      <router-view></router-view>
    </div>
    <Footer v-if="!$route.meta.showNav" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "App",
  data() {
    return {};
  },

  mounted() {
    document.title = "工业设计协同创新云平台";
  },
  methods: {
   
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
#app {
  font-family: Noto Sans SC, emoji;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  overflow-x: hidden;
  min-width: 1024px !important;
  /* overflow-y: auto; */
}

.mainWindow {
  display: flex;
  justify-content: center;
  min-width: 1024px;
}
</style>
 