import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const Home = () => import('../views/Home/Home');

const routes = [{
        path: '/',
        redirect: '/Home',
    }, {
        path: '/Home',
        name: 'Home',
        component: Home,
        meta: {
            title: '朗云工业设计协同创新云平台',
            content: {
                keywords: '朗云、朗云工业、工业设计、朗云工业设计、朗云工业设计创新云平台、朗云工业设计云平台',
                description: ''
            }
        }
    }, {
        path: '/Test',
        name: 'Test',
        component: () =>
            import ('../components/Test'),
    }, {
        path: '/Platform',
        name: 'Platform',
        component: () =>
            import ('../views/Home/Platform')
    }, {
        path: '/Login',
        name: 'Login',
        component: () =>
            import ('../views/Home/Login')
    }, {
        path: '/SignUp',
        name: 'SignUp',
        component: () =>
            import ('../views/Home/SignUp')
    }, {
        path: '/RequestDetail',
        name: 'RequestDetail',
        component: () =>
            import ('../views/Home/RequestDetail')
    }, {
        path: '/ServiceDetail',
        name: 'ServiceDetail',
        component: () =>
            import ('../views/Home/ServiceDetail')
    }, {
        path: '/ChangePassword',
        name: 'ChangePassword',
        component: () =>
            import ('../views/Home/ChangePassword.vue')
    }, {
        path: '/CaseTemp',
        name: 'CaseTemp',
        component: () =>
            import ('../views/Cases/CaseTemp'),
    }, {
        path: '/Case',
        name: 'Case',
        component: () =>
            import ('../views/Cases/Case'),
    }, {
        path: '/CaseDetail',
        name: 'CaseDetail',
        component: () =>
            import ('../views/Cases/CaseDetail')
    }, {
        path: '/Company',
        name: 'Company',
        component: () =>
            import ('../views/SupplyChain/Company')
    }, {
        path: '/ChainList',
        name: 'ChainList',
        component: () =>
            import ('../views/SupplyChain/ChainList')
    }, {
        path: '/ProjectHome',
        name: 'ProjectHome',
        component: () =>
            import ('../views/ProjectCenter/ProjectHome'),
    }, {
        path: '/Introduce',
        name: 'Introduce',
        component: () =>
            import ('../views/ProjectCenter/Introduce')
    },{
        path: '/UserAgreement',
        name: 'UserAgreement',
        component: () =>
            import ('../views/Home/UserAgreement')
    },{
        path: '/PrivateAgreement',
        name: 'PrivateAgreement',
        component: () =>
            import ('../views/Home/PrivateAgreement')
    },{
        path: '/ActivityList',
        name: 'ActivityList',
        component: () =>
            import ('../views/Activity/ActivityList.vue')
    },{
        path: '/SignActivity',
        name: 'SignActivity',
        component: () =>
            import ('../views/Activity/SignActivity.vue')
    },
    //企业后台页面
    {
        path: '/CompanyBackHome',
        name: 'CompanyBackHome',
        redirect: '/Publish',
        component: () =>
            import ('../views/Back/Company/CompanyBackHome'),
        children: [{
            path: '/CompanyContract',
            component: () =>
                import ('../views/Back/Company/ProjectManager/CompanyContract')
        }, {
            path: '/CompanyTender',
            component: () =>
                import ('../views/Back/Company/ProjectManager/CompanyTender')
        }, {
            path: '/CompanyAttend',
            component: () =>
                import ('../views/Back/Company/ProjectManager/CompanyAttend')
        }, {
            path: '/CheckFiles',
            component: () =>
                import ('../views/Back/Company/ProjectManager/CheckFiles')
        }, {
            path: '/WinBidResultDetail',
            component: () =>
                import ('../views/Back/Company/ProjectManager/WinBidResultDetail')
        }, {
            path: '/Complete',
            component: () =>
                import ('../views/Back/Company/ProjectManager/Complete')
        }, {
            path: '/Publish',
            component: () =>
                import ('../views/Back/Company/ProjectManager/Publish')
        }, {
            path: '/WinBidResult',
            component: () =>
                import ('../views/Back/Company/ProjectManager/WinBidResult')
        }, {
            path: '/CompanyAdd',
            component: () =>
                import ('../views/Back/Company/ProjectManager/CompanyAdd')
        }, {
            path: '/CorporateCertification',
            component: () =>
                import ('../views/Back/Company/AccountSetting/CorporateCertification')
        }, {
            path: '/Collected',
            component: () =>
                import ('../views/Back/Company/ProjectManager/Collected')
        }, {
            path: '/TradeManage',
            component: () =>
                import ('../views/Back/Company/MyAccount/TradeManage')
        }, {
            path: '/AccountManage',
            component: () =>
                import ('../views/Back/Company/MyAccount/AccountManage')
        }, {
            path: '/SetPassword',
            component: () =>
                import ('../views/Back/Company/MyAccount/SetPassword')
        }, {
            path: '/CompanyInformation',
            component: () =>
                import ('../views/Back/Company/AccountSetting/CompanyInformation')
        }, {
            path: '/CorporateCertification',
            component: () =>
                import ('../views/Back/Company/AccountSetting/CorporateCertification')
        }, {
            path: '/Comment',
            component: () =>
                import ('../views/Back/Company/MessagePage/Comment')
        }, {
            path: '/Fans',
            component: () =>
                import ('../views/Back/Company/MessagePage/Fans')
        }, {
            path: '/Like',
            component: () =>
                import ('../views/Back/Company/MessagePage/Like')
        }, {
            path: '/SystemMessage',
            component: () =>
                import ('../views/Back/Company/MessagePage/SystemMessage')
        }, {
            path: '/BidDetermine',
            component: () =>
                import ('../views/Back/Company/ProjectManager/BidDetermine')
        }
        ],
        //不显示父页面的组件
        meta: {
            showNav: true
        },
        //个人后台页面
    },
    {
        path: '/PersonalBackHome',
        name: 'PersonalBackHome',
        redirect: '/PersonalAttend',
        component: () =>
            import ('../views/Back/Personal/PersonalBackHome'),
        children: [{
            path: '/PersonalContract',
            component: () =>
                import ('../views/Back/Personal/ProjectManager/PersonalContract')
        }, {
            path: '/CompleteProject',
            component: () =>
                import ('../views/Back/Personal/ProjectManager/CompleteProject')
        }, {
            path: '/FileUpload',
            component: () =>
                import ('../views/Back/Personal/ProjectManager/FileUpload')
        }, {
            path: '/PersonalAttend',
            component: () =>
                import ('../views/Back/Personal/ProjectManager/PersonalAttend')
        }, {
            path: '/PersonalCollected',
            component: () =>
                import ('../views/Back/Personal/ProjectManager/PersonalCollected')
        }, {
            path: '/PersonalWinBid',
            component: () =>
                import ('../views/Back/Personal/ProjectManager/PersonalWinBid')
        }, {
            path: '/PersonalTender',
            component: () =>
                import ('../views/Back/Personal/ProjectManager/PersonalTender')
        }, {
            path: '/PersonalAdd',
            component: () =>
                import ('../views/Back/Personal/ProjectManager/PersonalAdd')
        }, {
            path: '/PersonalInformation',
            component: () =>
                import ('../views/Back/Personal/AccountSetting/PersonalInformation')
        }, {
            path: '/PersonalAccountManage',
            component: () =>
                import ('../views/Back/Personal/MyAccount/PersonalAccountManage')
        }, {
            path: '/PersonalSetPassword',
            component: () =>
                import ('../views/Back/Personal/MyAccount/PersonalSetPassword')
        }, {
            path: '/PersonalComment',
            component: () =>
                import ('../views/Back/Personal/MessagePage/PersonalComment')
        }, {
            path: '/PersonalFans',
            component: () =>
                import ('../views/Back/Personal/MessagePage/PersonalFans')
        }, {
            path: '/PersonalLike',
            component: () =>
                import ('../views/Back/Personal/MessagePage/PersonalLike')
        }, {
            path: '/PersonalSystemMessage',
            component: () =>
                import ('../views/Back/Personal/MessagePage/PersonalSystemMessage')
        }, {
            path: '/FirstDraft',
            component: () =>
                import ('../views/Back/Personal/ProjectManager/FirstDraft')
        }, {
            path: '/DesignCertification',
            component: () =>
                import ('../views/Back/Personal/AccountSetting/DesignCertification')
        }],
        //不显示父页面的组件
        meta: {
            showNav: true
        },
        //管理员后台
    },
    {
        path: '/AdministerBackHome',
        name: 'AdministerBackHome',
        redirect: '/AuditingProjects',
        component: () =>
            import ('../views/Back/Administer/AdministerBackHome'),
        children: [{
            path: '/AuditingProjects',
            component: () =>
                import ('../views/Back/Administer/ProjectManage/AuditingProjects')
        }, {
            path: '/ProjectDetail',
            component: () =>
                import ('../views/Back/Administer/ProjectManage/ProjectDetail')
        }, {
            path: '/AllProjects',
            component: () =>
                import ('../views/Back/Administer/ProjectManage/AllProjects')
        }, {
            path: '/AllCases',
            component: () =>
                import ('../views/Back/Administer/CasesManage/AllCases')
        }, {
            path: '/CaseAdd',
            component: () =>
                import ('../views/Back/Administer/CasesManage/CaseAdd')
        }, {
            path: '/AddSupply',
            component: () =>
                import ('../views/Back/Administer/SupplyManage/AddSupply')
        }, {
            path: '/AllSupply',
            component: () =>
                import ('../views/Back/Administer/SupplyManage/AllSupply')
        }, {
            path: '/AllProject',
            component: () =>
                import ('../views/Back/Administer/SupplyManage/AllProject')
        }, {
            path: '/AddProject',
            component: () =>
                import ('../views/Back/Administer/SupplyManage/AddProject')
        }, {
            path: '/AllAdvance',
            component: () =>
                import ('../views/Back/Administer/SupplyManage/AllAdvance')
        }, {
            path: '/AllCertificate',
            component: () =>
                import ('../views/Back/Administer/SupplyManage/AllCertificate')
        }, {
            path: '/SuAllCase',
            component: () =>
                import ('../views/Back/Administer/SupplyManage/SuAllCase')
        }, {
            path: '/SuCaseAdd',
            component: () =>
                import ('../views/Back/Administer/SupplyManage/SuCaseAdd')
        }, {
            path: '/ContractAuditing',
            component: () =>
                import ('../views/Back/Administer/ContractManage/ContractAuditing')
        }, {
            path: '/ContractUpload',
            component: () =>
                import ('../views/Back/Administer/ContractManage/ContractUpload')
        }, {
            path: '/HeadPicManage',
            component: () =>
                import ('../views/Back/Administer/WebSiteManage/HeadPicManage')
        }, {
            path: '/SignList',
            component: () =>
                import ('../views/Back/Administer/Acitvity/SignList')
        }, {
            path: '/ActivityManage',
            component: () =>
                import ('../views/Back/Administer/Acitvity/ActivityManage')
        }],
        //不显示父页面的组件
        meta: {
            showNav: true
        },
    },
    {
        path: '/BackHome',
        component: () =>
            import ('../views/Back/BackHome')
    }, {
        path: '/404',
        name: '404',
        component: () =>
            import ('../views/404'),
        meta: {
            showNav: true
        },
    },
    {
        path: '/D',
        name: 'ADLOG',
        component: () =>
            import ('../views/Home/ADLOG'),
        meta: {
            showNav: true
        },
    },

]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) { //这个处理的是window对象的滚动
        if (savedPosition) {
            return savedPosition
        }
        return {
            y: 0,
            top: 0
        }
    },
})

//导航守卫
//开启前先做白名单
/*
router.beforeEach((to, from, next) => {
    //页面拦截
    if (to.name !== 'ActivityList' && to.name !=='SignActivity' && to.name !== 'PrivateAgreement' && to.name !== 'UserAgreement' && to.name !== 'Test' && to.name !== 'ADLOG' && to.name !== 'CaseDetail' && to.name !== 'Home' && to.name !== 'Login' && to.name !== 'SignUp' && to.name !== 'Platform' && to.name !== 'RequestDetail' && to.name !== 'ServiceDetail' && to.name !== 'ProjectHome' && to.name !== 'Introduce' && to.name !== 'ChainList' && to.name !== 'Company' && to.name !== 'Case' && to.name !== 'CaseTemp') {
        let role = window.sessionStorage.getItem("role");
        let token = window.sessionStorage.getItem("token");
        if (!token) {
            return next({ name: 'Login' });
        }
        return next();
    } else {
        return next();
    }
})
*/


export default router
