import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import 'babel-polyfill'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


//记得做懒加载

import '@/assets/normalize.css'
import axios from 'axios'

// 引入比例缩放文件
import './utils/rem.js'

const app = createApp(App)
installElementPlus(app)
app.use(store).use(router).mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.$http = axios;
//接口请求的基准路径
axios.defaults.baseURL = 'https://www.iloncloud.cn:8082';

// 添加请求拦截器
axios.interceptors.request.use(config => {
    // 在发送请求之前做些什么

    // 判断是否存在token,如果存在将每个页面header添加token

    if (sessionStorage.getItem("token")) {
        config.headers.token = sessionStorage.getItem("token");
    }

    return config
})

//WangEditorV4富文本编辑器
import E from 'wangeditor'
const editor = new E('#div')
editor.create()
